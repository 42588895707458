/* Общие стили для всех страниц */
.page {
  padding: 6px;
  max-width: 100%;
  margin: 0 auto;
  color: var(--tg-theme-text-color, #ffffff);
  font-size: 11px;
}

/* Основные блоки */
.content-block {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 6px;
}

/* Заголовки */
.section-header {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--tg-theme-text-color, #ffffff);
}

/* Строки информации */
.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 4px 6px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  font-size: 11px;
}

/* Кнопки */
.action-button {
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  border: none;
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  font-size: 11px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 24px;
}

/* Списки */
.list-container {
  gap: 3px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 4px;
  margin-right: -4px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.05);
}

/* Стили для скроллбара */
.list-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.list-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.list-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  transition: background 0.2s ease;
}

/* Элементы списка */
.list-item {
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 9px;
  background: rgba(255, 255, 255, 0.03);
  margin-bottom: 3px;
}

/* Значения */
.value {
  font-weight: 500;
  background: none;
  border: none;
  outline: none;
}

.value.highlight {
  color: var(--tg-theme-button-color, #50a8eb);
  background: none;
  border: none;
  outline: none;
}

/* Числовые значения */
.number {
  background: none;
  border: none;
  outline: none;
}

/* Дополнительно убираем обводку для всех числовых элементов */
[class*="value"],
[class*="amount"],
[class*="power"],
[class*="energy"] {
  background: none;
  border: none;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

/* Адаптивные стили */
@media (max-width: 320px) {
  .page {
    padding: 4px;
    font-size: 10px;
  }
}

/* Дополнительные стили для рейтинга */
.username {
  color: var(--tg-theme-button-color, #50a8eb);
  font-weight: 500;
}

.power {
  color: #FFD700;
  font-size: 9px;
}

.equipment {
  font-size: 9px;
  opacity: 0.7;
}

/* Стили для заданий */
.task-name {
  font-size: 11px;
}

.reward {
  color: #FFD700;
  font-size: 9px;
}

.progress-bar-container {
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
  margin-top: 4px;
}

.progress-bar {
  height: 100%;
  background: var(--tg-theme-button-color, #50a8eb);
  transition: width 0.3s ease;
}

/* Стили для текстового контента */
.text-content {
  font-size: 11px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  padding: 4px 6px;
}

/* Стили для прогресс-бара энергии */
.energy-bar-container {
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 6px;
}

.energy-bar {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
  transition: width 0.3s ease;
}

.energy-bar.low {
  background: linear-gradient(90deg, #FF5252, #FF8A80);
}

.energy-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 11px;
}

.energy-section {
  margin-bottom: 10px;
}

/* Стили для рейтинга */
.list-item.top-10 {
  background: rgba(255, 215, 0, 0.05);
  border-left: 2px solid #FFD700;
}

.miner-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rank {
  font-size: 9px;
  opacity: 0.7;
  min-width: 20px;
}

.user-rank {
  margin-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 8px;
}

.list-item.current-user {
  background: rgba(80, 168, 235, 0.05);
  border-left: 2px solid var(--tg-theme-button-color, #50a8eb);
}

/* Стили для реферальной системы */
.copy-button {
  background: none;
  border: none;
  color: var(--tg-theme-button-color, #50a8eb);
  font-size: 11px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.copy-button:hover {
  background: rgba(255, 255, 255, 0.05);
}

.referral-info {
  margin-top: 8px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  font-size: 11px;
}

.referral-info p {
  margin: 0 0 4px 0;
  color: rgba(255, 255, 255, 0.7);
}

.referral-info ul {
  margin: 0;
  padding-left: 16px;
  list-style-type: none;
}

.referral-info li {
  margin: 2px 0;
  position: relative;
}

.referral-info li:before {
  content: "•";
  color: var(--tg-theme-button-color, #50a8eb);
  position: absolute;
  left: -12px;
}

.subscription-actions {
  display: flex;
  gap: 8px;
}

.subscribe-button, .check-button {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.subscribe-button {
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  text-decoration: none;
}

.check-button {
  background: rgba(255, 255, 255, 0.1);
  color: var(--tg-theme-button-color, #50a8eb);
  border: none;
}

.reward-claimed {
  color: #4CAF50;
  font-size: 11px;
}

/* Стили для каналов */
.channel-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.channel-title {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.channel-link {
  font-size: 9px;
  color: var(--tg-theme-button-color, #50a8eb);
  text-decoration: none;
  opacity: 0.8;
}

.channel-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.subscription-actions {
  display: flex;
  gap: 6px;
}

.subscribe-button, .check-button {
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 9px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.subscribe-button {
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  text-decoration: none;
}

.check-button {
  background: rgba(255, 255, 255, 0.1);
  color: var(--tg-theme-button-color, #50a8eb);
  border: none;
}

.reward-claimed {
  color: #4CAF50;
  font-size: 9px;
}

/* Стили для списка заданий */
.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.task-item {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  padding: 8px;
  position: relative;
  transition: all 0.3s ease;
  opacity: 1;
}

.task-item.completed {
  border-left: 2px solid #4CAF50;
  background: rgba(76, 175, 80, 0.05);
  opacity: 0.7;
}

.task-item.completed:hover {
  opacity: 0.9;
}

.task-item.completed .progress-bar {
  background: #4CAF50;
}

.reward-claimed {
  display: block;
  text-align: center;
  margin-top: 6px;
  font-size: 10px;
  color: #4CAF50;
  font-weight: 500;
}

.progress-text {
  font-size: 9px;
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 2px;
}

.task-item.completed .progress-text {
  color: #4CAF50;
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.task-title {
  font-size: 11px;
  font-weight: 500;
}

.task-reward {
  font-size: 10px;
  color: #FFD700;
}

.task-description {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 6px;
}

.task-progress {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 4px;
}

.progress-bar-container {
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: var(--tg-theme-button-color, #50a8eb);
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 9px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}

.claim-button {
  width: 100%;
  padding: 4px;
  margin-top: 6px;
  border: none;
  border-radius: 4px;
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.claim-button:hover {
  opacity: 0.9;
}

.claim-button:active {
  transform: scale(0.98);
}

.task-item.completed .claim-button {
  background: #4CAF50;
}

.reward-claimed {
  display: block;
  text-align: center;
  margin-top: 6px;
  font-size: 10px;
  color: #4CAF50;
}

.task-item.completed {
  border-left: 2px solid #4CAF50;
}

/* Стили для страницы About */
.info-text {
  font-size: 10px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.7);
  padding: 2px 0;
}

.info-item, .mechanics-item, .warning-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  margin: 4px 0;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.02);
}

.info-number {
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 9px;
  flex-shrink: 0;
}

.mechanics-icon, .warning-icon {
  font-size: 12px;
  flex-shrink: 0;
}

.warning-item {
  background: rgba(255, 82, 82, 0.05);
  border-left: 2px solid #FF5252;
}

.support-text {
  text-align: center;
  padding: 8px;
  font-size: 9px;
  line-height: 1.5;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
}

.support-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  margin: 4px 0;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.02);
  font-size: 9px;
}

.support-icon {
  font-size: 12px;
  flex-shrink: 0;
}

.support-note {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  margin-top: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.03);
  font-size: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
}