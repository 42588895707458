.navigation {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  background-color: var(--block-bg);
  padding: 8px 4px;
  border-top: 1px solid rgba(0, 255, 157, 0.2);
  z-index: 1000;
}

.navigation::after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  height: 30px;
  background-color: var(--block-bg);
  z-index: -1;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 4px;
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 7px;
  padding: 4px;
  transition: color 0.3s;
  font-family: 'Press Start 2P', cursive;
  letter-spacing: 0;
  opacity: 0.5;
  text-align: center;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
}

.nav-link:hover {
  color: var(--accent-color);
  opacity: 0.8;
}

.nav-link.active {
  color: var(--accent-color);
  opacity: 1;
}

/* Адаптация под разные размеры экранов */
@media (max-width: 320px) {
  .nav-link {
    font-size: 6px;
    padding: 4px 2px;
  }

  .navigation {
    padding: 6px 2px;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .nav-link {
    font-size: 7px;
    padding: 4px;
  }
}

@media (min-width: 376px) and (max-width: 414px) {
  .nav-link {
    font-size: 8px;
    padding: 4px 6px;
  }
}

@media (min-width: 415px) {
  .nav-link {
    font-size: 8px;
    padding: 4px 8px;
  }

  .nav-links {
    max-width: 320px;
  }
}

/* Для устройств с высоким DPI */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .nav-link {
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: unset;
  }
}

/* Для устройств с поддержкой hover */
@media (hover: hover) {
  .nav-link:hover {
    opacity: 0.8;
  }
}