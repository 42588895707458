:root {
  --primary-bg: #1a1a1a;
  --secondary-bg: #2d2d2d;
  --accent-color: #00ff9d;
  --text-color: #ffffff;
  --block-bg: rgba(0, 0, 0, 0.85);
  --tg-theme-button-color: var(--accent-color);
  --tg-theme-button-text-color: var(--primary-bg);
  --tg-viewport-height: 100vh;
  --tg-viewport-stable-height: 100vh;
  --tg-header-height: 56px;
  --safe-area-top: env(safe-area-inset-top, 20px);
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.app {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-bg);
  color: var(--text-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-content {
  flex: 1;
  margin-top: 0;
  padding: 4px 12px;
  padding-top: max(var(--tg-header-height), calc(var(--safe-area-top) + 56px));
  padding-bottom: 60px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  width: 100%;
  height: calc(var(--tg-viewport-stable-height));
  background-color: var(--block-bg);
  position: relative;
  z-index: 1;
}

@supports (padding-top: env(safe-area-inset-top)) {
  .main-content {
    margin-top: 0;
    padding-top: max(
      var(--tg-header-height),
      calc(env(safe-area-inset-top) + 56px)
    );
    padding-left: calc(env(safe-area-inset-left) + 12px);
    padding-right: calc(env(safe-area-inset-right) + 12px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
  }
}

.mining-page,
.rating-page,
.about-page,
.tasks-page {
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  width: 100%;
}

.profile-block,
.coin-info-block,
.mining-info-block,
.mining-history-block,
.rating-block,
.about-block,
.tasks-block,
.history-item,
.rating-item,
.about-section,
.spec-item,
.energy-spec-item {
  background-color: var(--block-bg);
  border-radius: 6px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.info-box,
.feature-list,
.spec-item,
.energy-spec-item,
.history-item,
.rating-item,
.bonus-box,
.info-note,
.equipment-control,
.mining-power-info .info-row {
  padding: 8px;
}

.highlight,
.accent {
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.warning {
  border: 1px solid rgba(255, 68, 68, 0.3);
}

button {
  font-family: 'Press Start 2P', cursive;
  font-size: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

h2 {
  font-size: 10px;
  margin: 0 0 8px 0;
  color: var(--accent-color);
}

.value,
.balance-value,
.energy-value,
.total-supply,
.miners-count,
.detail-value,
.mined-amount {
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
  color: var(--accent-color);
}

@media (min-width: 768px) {
  .main-content {
    max-width: 360px;
    margin: 0 auto;
  }
}