.mining-page {
  padding: 6px;
  max-width: 100%;
  margin: 0 auto;
  color: var(--tg-theme-text-color, #ffffff);
  font-size: 11px;
}

/* Основной блок */
.main-stats-block {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 6px;
}

/* Баланс */
.balance-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
}

.balance-section .label {
  display: block;
  margin-bottom: 2px;
  font-size: 11px;
}

.balance-value {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
}

.balance-value .value {
  font-size: 13px;
  font-weight: 500;
  color: var(--tg-theme-button-color, #50a8eb);
  background: none;
  border: none;
  line-height: 1.3;
}

.balance-value .currency {
  font-size: 11px;
  opacity: 0.8;
  background: none;
  border: none;
}

/* Энергия */
.energy-section {
  margin-bottom: 10px;
}

.energy-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 11px;
}

.energy-bar-container {
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 6px;
}

.energy-bar {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
  transition: width 0.3s ease;
}

.energy-bar.low {
  background: linear-gradient(90deg, #FF5252, #FF8A80);
}

/* Кнопки */
.upgrade-button, .buy-button {
  width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: var(--tg-theme-button-color, #50a8eb);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 11px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
}

.buy-button {
  width: auto;
  padding: 4px 8px;
  margin-left: 4px;
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  font-size: 9px;
  white-space: nowrap;
  line-height: 24px;
}

/* Содержимое кнопок */
.upgrade-button span,
.buy-button span {
  display: flex;
  align-items: center;
  height: 100%;
}

.cost {
  color: #FFD700;
  font-size: 9px;
  margin-left: 4px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.upgrade-button:hover, .buy-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

.buy-button:hover {
  background: var(--tg-theme-button-color, #50a8eb);
  opacity: 0.9;
}

.upgrade-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Информация о майнинге */
.mining-info {
  margin: 10px 0;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
  font-size: 9px;
  line-height: 1.3;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  margin-bottom: 4px;
}

.info-row .label {
  margin-bottom: 2px;
}

.info-row.highlight {
  background: rgba(76, 175, 80, 0.1);
}

.info-row.failed {
  background: rgba(255, 82, 82, 0.1);
}

.info-row.success {
  background: rgba(76, 175, 80, 0.1);
}

.failed-attempt {
  color: #FF5252;
}

.equipment-control {
  display: flex;
  align-items: center;
  gap: 4px;
}

.equipment-control .value {
  margin-right: 4px;
  white-space: nowrap;
}

.equipment-control .upgrade-button {
  font-size: 8px;
  padding: 2px 4px;
  height: 24px;
  min-height: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.equipment-control .cost {
  font-size: 8px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 24px;
}

/* Кнопка майнинга */
.mining-button {
  width: 100%;
  height: 34px;
  border: none;
  border-radius: 6px;
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  line-height: 1.2;
}

.mining-button.active {
  background: #FF5252;
}

.mining-button:hover {
  opacity: 0.9;
}

.mining-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mining-button .warning {
  font-size: 10px;
}

/* Статистика */
.coin-stats {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 6px;
  margin-bottom: 6px;
}

.stats-row {
  display: flex;
  justify-content: space-around;
  font-size: 10px;
}

.stat-item {
  text-align: center;
}

.stat-item .label {
  font-size: 9px;
  opacity: 0.7;
}

.stat-item .value {
  font-size: 10px;
  font-weight: bold;
}

/* История */
.mining-history {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 6px;
  margin-top: 6px;
}

.mining-history h3 {
  font-size: 11px;
  margin: 0 0 4px 0;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.05);
  padding: 4px;
  border-radius: 4px;
}

.history-list {
  gap: 3px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 4px;
  margin-right: -4px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.05);
}

.history-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.history-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.history-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  transition: background 0.2s ease;
}

.history-list::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.history-list {
  -webkit-overflow-scrolling: touch;
}

.history-list {
  scroll-behavior: smooth;
}

.history-item {
  padding: 6px 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  font-size: 9px;
  line-height: 1.3;
  margin-bottom: 4px;
}

.history-item.success {
  border-left: 2px solid #4CAF50;
}

.history-item.failed {
  border-left: 2px solid #FF5252;
  opacity: 0.8;
  background: rgba(255, 82, 82, 0.05);
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.history-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.username {
  font-size: 9px;
  color: var(--tg-theme-button-color, #50a8eb);
  line-height: 1.2;
}

.timestamp {
  font-size: 8px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.2;
}

.power {
  font-size: 8px;
  color: #FFD700;
  line-height: 1.2;
}

.amount {
  font-size: 8px;
  line-height: 1.2;
}

.amount.success {
  color: #4CAF50;
}

.amount.failed {
  color: #FF5252;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.action-button.stop {
  background: #FF5252;
}

/* Общие стили */
.label {
  opacity: 0.7;
}

.highlight {
  color: var(--tg-theme-button-color, #50a8eb);
}

.icon {
  font-size: 11px;
}

.warning {
  color: #FF5252;
  font-size: 9px;
}

/* Адаптивные стили */
@media (max-width: 320px) {
  .mining-page {
    padding: 4px;
    font-size: 10px;
  }

  .balance-section .value {
    font-size: 12px;
  }

  .mining-button {
    height: 28px;
    font-size: 11px;
  }

  .history-list {
    max-height: 120px;
  }
}

/* Основные блоки */
.main-stats {
  margin-bottom: 8px;
}

.balance-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
}

.balance-section .value {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
}

.stat-item .label {
  font-size: 8px;
  opacity: 0.7;
  margin-bottom: 3px;
  line-height: 1.2;
}

.stat-item .value {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
}

/* Кнопки управления */
.control-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mining-button {
  width: 100%;
  height: 34px;
  border: none;
  border-radius: 6px;
  background: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  line-height: 1.2;
}

.mining-button.active {
  background: #FF5252;
  animation: pulse 2s infinite;
}

.mining-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mining-button .warning {
  font-size: 10px;
  opacity: 0.8;
}

.action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;
}

.action-button {
  height: 36px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: var(--tg-theme-text-color, #ffffff);
  font-size: 9px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:active {
  transform: scale(0.98);
  background: rgba(255, 255, 255, 0.08);
}

.button-content {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.button-content span {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
}

.cost {
  font-size: 11px;
  color: #4CAF50;
  font-weight: 500;
}

.star-cost {
  font-size: 12px;
  color: #FFD700;
  font-weight: 500;
}

/* Статистика майнинга */
.mining-stats {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 8px;
}

.last-reward, .total-mined {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  font-size: 9px;
  line-height: 1.3;
}

.success {
  color: #4CAF50;
}

.failed {
  color: #FF5252;
}

/* История майнинга */
.history-block {
  margin-top: auto;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.history-item {
  padding: 6px 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  font-size: 9px;
  line-height: 1.3;
  margin-bottom: 4px;
}

.history-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.history-details {
  display: flex;
  justify-content: space-between;
}

/* Онлайн статистика */
.online-stats {
  margin-top: auto;
}

/* Анимации */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.mining-button.active {
  animation: pulse 2s infinite;
}